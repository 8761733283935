import React , {useState, useRef}from 'react';
import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Burger, Menu, Reader, Author, AddBook, UpdateBook, DeleteBook, ReadBook, MainPage} from './components';
import { UseOnClickOutside } from './Hook';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import { theme } from './theme';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [open, setOpen] = useState(false);
  const node = useRef(); 
  UseOnClickOutside(node, () => setOpen(false));
  return (
    <ThemeProvider theme={theme} style={{backgroundImage:"url(./logo.jpg)"}}>
      <MainPage/>
      <GlobalStyles />
    <AmplifyAuthenticator usernameAlias="email">
    <AmplifySignUp
      headerText="Sign up to read book today"
      slot="sign-up"
      usernameAlias="email"
      formFields={[
        {
          type: "email",
          label: "Your current email",
          placeholder: "kenny@gmail.com",
          inputProps: { required: true, autocomplete: "username" },
        },
        {
          type: "password",
          label: "Your current password",
          placeholder: "",
          inputProps: { required: true, autocomplete: "new-password" },
        },
        {
          type: "phone_number",
          label: "Mobile phone",
          placeholder: "",
        },
      ]} 
    />
    <AmplifySignIn slot="sign-in" usernameAlias="email"  headerText="Hello, how are you today?"/>
      <div ref={node}>
          <Burger open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} />
        </div>  
        <Router>
        <Switch>
          <Route  exact path="/">
            <Reader />
          </Route>
          <Route  exact path="/author">
            <Author />
          </Route>
          <Route  exact path="/author/create">
            <AddBook />
          </Route>
          <Route  exact path="/author/update/:id/:title/:code">
            <UpdateBook />
          </Route>
          <Route  exact path="/author/delete/:id/:title/:code/:version">
            <DeleteBook />
          </Route>
          <Route  exact path="/reader">
            <ReadBook />
          </Route>
        </Switch>
        </Router>
    </AmplifyAuthenticator>
    </ThemeProvider>
  );
}

export default App
// export default withAuthenticator(App)
