import React, {useState, useEffect} from 'react'
import { API, graphqlOperation, Storage, Analytics } from 'aws-amplify';
import { createBook } from '../../graphql/mutations';
import {Link} from 'react-router-dom';
import { BookStyle, Main } from './AddBook.styled';
import {AuthorHeader} from '../';
import { Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert';


function BookForm(props){
  const [title, setTitle] = useState('')
  const [content, setContent] = useState({})
  const [code, setCode] = useState('')


  const handleTitle = (e)=> {
    setTitle(e.target.value)
  }

  // generate code automatically
  const handleCode = (e) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 12; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setCode(result)
  }

  // upload content on S3 as a link to store as html
  const handleContent = (e)=> {
    const file = e.target.files[0]
    setContent({
      fileUrl:URL.createObjectURL(file),
      file:file,
      filename:file.name
    })

  }

  // when user pull out the code, they can get the link

  const handleSubmit = (e)=>{
    e.preventDefault();
    props.addBook(title,code,content)
  }
  return(
    <>
    <Main>
    <AuthorHeader/>
        <BookStyle onSubmit={handleSubmit} onChange={handleCode}>
        <h3>
          Create new book
        </h3>
          <label for="title">Book Title</label>
          <input required style={{padding:"5px", width:"40vw"}} type="text" id="title" name="title" placeholder="Book title" value={title}  onChange={handleTitle}/>
          <p>
          <label for="title">Upload your book (*.pdf)</label>
          <input required style={{padding:"5px"}}type="file" id="content" name="content"  accept="application/pdf" onChange={handleContent}/>
          </p>
          <p>
          <button style={{padding:"10px"}} value="submit">Submit</button>
          </p>
        </BookStyle>
    </Main>
    </>
  )
}
function addBook(){
  
  const addBook = async(title,code,content) => {
    try {
      await API.graphql(
        graphqlOperation(createBook, {input:  
        {
          title:title,
          code:code,
          content:content,
        }
        })
      )
      await Storage.put(code, content.file, {
        ContentType:"pdf/*",
        body: content
      })
      .then (result => Swal({title: "Your code is: " + code,  icon: "success"})
      ) 
      .catch(error => alert("Upload error, please check your internet"));
      
     }
      catch (err){
        console.log(err)
    }
  }

  return (
    <div>
       <BookForm addBook={addBook}></BookForm>
    </div>
  )
}

export default addBook;