import React, {useState, useEffect} from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import { listBooks } from '../../graphql/queries';
import {Link} from 'react-router-dom';
import { Main } from './Author.styled';
import {AuthorHeader} from '../';
import { Button, Table} from 'react-bootstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faCoffee, faCopy, faEdit, faTrash } from '@fortawesome/fontawesome-free-solid'



function Author(){
  
  const [list, setList ] = useState([])
  const [copy, setCopy] = useState(false)
  const createBook = async () => {
    const allBooks = await API.graphql(graphqlOperation(listBooks))
    setList(allBooks.data.listBooks.items)
  }

useEffect(()=>{
    createBook()
    },list)
  
    return (
    <div>
      <Main>
        <AuthorHeader/>
          <h3>View all books</h3>
          <table responsive="sm" hover>
          <tr>
          <th>Title</th>
          <th>Code</th>
          <th style={{backgroundColor:"white",padding:"0.2rem"}}>Update</th>
          <th style={{backgroundColor:"#d23a47", color:"white",padding:"0.2rem"}}>Delete</th>
          </tr>
        {list.map((item) => {
          if (item["_deleted"] !== true){
          return <tr>
                    <td key={item.id}> { item.title }</td>
                    <td> {item.code}    
                    <CopyToClipboard style={{float:"right",padding:"0.2rem",backgroundColor:"black"}}text={item.code}
                      onCopy={() => setCopy(true)}>
                      <button>
                      <FontAwesomeIcon icon={faCopy} />
                      </button>
                      </CopyToClipboard></td>
                    <td>
                    <Link to={`/author/update/${item.id}/${item.title}/${item.code}`}><button responsive style={{backgroundColor:"white",color:"black"}}>
                      <FontAwesomeIcon icon={faEdit} />
                      </button></Link>
                    </td>
                    {/* pop out a box to confirm title to delete */}
                    <td> 
                    <Link to={`/author/delete/${item.id}/${item.title}/${item.code}/${item._version}`}><button responsive style={{backgroundColor:"rgb(210, 58, 71)"}}>
                      <FontAwesomeIcon icon={faTrash} />
                      </button></Link>
                    </td>
                </tr>
          }
        }
        )
        }
        </table>
        </Main>
      
    </div>
  )
}

export default Author;