import styled from 'styled-components';
export const DeleteStyle = styled.form`
    color: black;
    display: block;
    background-color: #ff8d8d;
    width: 60vw;
    display: inline-table;
    border-spacing: 0.2rem;
    box-shadow: 5px 10px #888888;
    `;

export const Main = styled.div`
    color: black;
    display: block;
    width: 100vw

    
    `;