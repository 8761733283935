import styled from 'styled-components';
export const UpdateStyle = styled.form`
    color: black;
    display: inline-table;
    background-color: white;
    width: 60vw;
    border-spacing: 0.2rem;
    box-shadow: 5px 10px #888888;
    `;

export const Main = styled.div`
    color: black;
    display: block;
    width: 100vw
    
    `;