import React, {useState, useEffect} from 'react';
import { Document, Page,  Outline} from 'react-pdf/dist/esm/entry.webpack';
import { Main } from './Readbook.styled';
import { API, graphqlOperation, Storage, Analytics, a } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';

function ReadBook(code){

  const pdfBook = code
  const [file,setFile] = useState({})
  const [bookDisplay, setbookDisplay] = useState("none")

  const pullBook = async(pdfBook) => {
    console.log(pdfBook)
    await Storage.get(code, { download: true })
    .then((res) =>{ 
      console.log(res.Body)
      // setFile(res.Body)
    }
    ).then(()=>{
      setbookDisplay("flex")

    }).catch((err)=> alert("You enter invalid code, try again"))
  }
    // get pdf version here to get the data
    // setpdfBook(code)
    // console.log(pdfBook)

    // useEffect(() => {
    //   setpdfBook(code)
    //   // console.log(code)
    // }, [])
    // const [numPages, setNumPages] = useState(null)
    // const [pageNumber, setPageNumber] = useState(1)
    
    // function onDocumentLoadSuccess({numPages}){
    //     setNumPages(numPages)
    //     setPageNumber(1);
    // }

    // function changePage(offset) {
    //     setPageNumber(prevPageNumber => prevPageNumber + offset);
    //   }
    
    //   function previousPage() {
    //     changePage(-1);
    //   }
    
    //   function nextPage() {
    //     changePage(1);
    //   }

    //   function onItemClick({ pageNumber: itemPageNumber }) {
    //     setPageNumber(itemPageNumber);
    //   }
    
    // return(
      
    //     <Main style={{display:"flex", marginTop: "5%"}}>
    //     <div>
    //     <div style={{overflow:"scroll", width: "30vw",height: "100vh"}} >

    //     <Document 
    //             file={pdfFile}
    //             onLoadSuccess={onDocumentLoadSuccess}
    //         >
    //               <Outline onItemClick={onItemClick} />
    //       </Document>
    //       </div>
    //   </div>
    //   <Document 
    //             file={pdfFile}
    //             onLoadSuccess={onDocumentLoadSuccess}
    //         >
    //             <Page pageNumber={pageNumber} />
    //             <div style={{textAlign:"center"}}>
    //       Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
    //     </div>
    //         </Document>
  
    //     <div style={{margin:"2%"}}>
    //     <button style={{ padding: "2%", top: "20%", position: "fixed"}}
    //       type="button"
    //       disabled={pageNumber <= 1}
    //       onClick={previousPage}
    //     >
    //       Previous
    //     </button>
    //     <button  style={{ padding: "3%", top: "30%", position: "fixed", border: "solid 1px green"}}
    //       type="button"
    //       disabled={pageNumber >= numPages}
    //       onClick={nextPage}
    //     >
    //       Next
    //     </button>
    //     </div>

    //     </Main>
    // )
}


export default ReadBook