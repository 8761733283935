/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://eqtt25nk5bhbdohyvyjljfo4wi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-gvxgt3tgcffordwoalze5rtuku",
    "aws_cognito_identity_pool_id": "us-west-2:d7aee8be-1600-4945-a4e2-9d7416c5c071",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_CPcEvlBwf",
    "aws_user_pools_web_client_id": "51302r8oq8o8k060kh10tbtnq0",
    "oauth": {},
    "aws_user_files_s3_bucket": "reedstreetpressfinaldece58935ad84bfb9bb2246d42a172200-local",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
