import React, {useState, useEffect} from 'react'
import { API, graphqlOperation } from 'aws-amplify';
import { Main } from './MainPage.styled';
import { Button} from 'react-bootstrap';
import Modal from "react-modal"

function AboutUs(){
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  
  return (
  <>
     <a href="#" onClick={toggleModal} class="link-info">reedstreetpress.com</a>
      <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}>
          <div class="modal-body" style={{textAlign:"center"}}>
            <h1>
              reetstreetpress.com @ KennyDN
            </h1>
            <p>
              Instruction about reed street press 
            </p>
                  </div>
                  <div class="modal-footer">
                  <Button style={{right:"0%",  top:"0%", position: "absolute"}} onClick={toggleModal}>X</Button>
                  </div>
                </Modal>
</>
  );
}


function Instruction() {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  
  return (
  <>
    <span><a href="#" onClick={toggleModal} class="link-info">Instruction&nbsp;</a></span>
      <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}>
          <div class="modal-body" style={{textAlign:"center"}}>
          <h1>Welcome everyone</h1>
          <p>To read any book that you donate from your favorite author, get your code ready
            in the receipt
            </p>
                  <ul>
                    <li>
                      <p>
                      1. Sign up with your email and password
                      </p>
                    </li>
                    <li>
                      <p>
                      2. Copy code from receipt that you donate for your author in your email receipt
                      </p>
                    </li>
                    <li>
                      <p>
                      3. Paste the code in the Reader page to read the book
                      </p>
                    </li>
                    <li>
                      <p>
                      4. Have fun
                      </p>
                    </li>
                    <li>
                      <p>
                      5. Feedback or help, please send to kennyDN@gmail.com to get support 24/7
                      </p>
                    </li>
                  </ul>
                  </div>
                  <div class="modal-footer">
                  <Button style={{right:"0%",  top:"0%", position: "absolute"}} onClick={toggleModal}>X</Button>
                  </div>
                </Modal>
</>
  );
}

function SaveList(){
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  
  return (
    <>
     <a href="#" onClick={toggleModal} class="link-info">Saved List</a>
      <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}>
          <div class="modal-body" style={{textAlign:"center"}}>
            <h1>
              Saved code
            </h1>
            <p>
              #create code that is save
            </p>
            <p>
              Title of book: code
            </p>
                  </div>
                  <div class="modal-footer">
                  <Button style={{right:"0%",  top:"0%", position: "absolute"}} onClick={toggleModal}>X</Button>
                  </div>
                </Modal>
      </>
    );
  }

function MainPage(){
  return (
    <Main>
        <ul style={{display:"inline-flex", top:"5%"}}>
          <li style={{left:"5px",position:"absolute",textDecoration:"none",listStyleType: "none", fontSize:"1.5vw"}}>
            <AboutUs />
            </li>
          <li style={{textDecoration:"none",listStyleType: "none", fontSize:"1.5vw"}}>
            <Instruction />
            </li>
            <li style={{textDecoration:"none",listStyleType: "none", fontSize:"1.5vw"}}>
            <SaveList />
            </li>
        </ul>
    </Main>   
  )
}

export default MainPage;