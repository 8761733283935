import styled from 'styled-components';
export const BookStyle = styled.form`
    color: black;
    display: block;
    width: 60vw;
    background-color: lavender;
    display: inline-table;
    box-shadow: 5px 10px #888888;
    padding: 0.5rem;

    `;

export const Main = styled.div`
    color: black;
    display: block;
    width: 100vw
    
    `;