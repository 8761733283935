import React, {useState, useEffect} from 'react'
import { getBook } from '../../graphql/queries'
import {ReadBook} from '../'
import { API, graphqlOperation, Storage, Analytics, a } from 'aws-amplify';
import { booksByCode } from '../../graphql/queries'
import { Document, Page,  Outline} from 'react-pdf/dist/esm/entry.webpack';
import { Main } from './Reader.styled';
import Zoom from 'react-reveal/Zoom';
import 'bootstrap/dist/css/bootstrap.min.css';

function CodeForm(props){
  //do something the same like update book - passing the id to it and console.log data
  //pass data to Readbook js so it can read the file
  const [code, setCode] = useState('')
  const [url, setUrl] = useState({})
  const [content, setContent] = useState("")

  const handleCode = (e)=> {
    setCode(e.target.value)
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    props.pullBook(code).catch((error)=>{
      alert("You enter invalid code, try again or contact us")
     }
    )
  }

  const handleSave = (e) =>{
    e.preventDefault()
    console.log(e.target.value)
  }

  return (
    <>
    <Main>
      <form onSubmit={handleSubmit} >
    <h1>
      I am Reader
    </h1>
    <input style={{padding:"20px", marginRight:"5px", width: "33%"}} type="text" value={code} onChange={handleCode} placeholder="Enter your code to read the book"/>
    <button style={{padding:"25px"}} value="submit" >Enter</button>
    </form>
    {/* <form onSubmit={handleSave}>
    <input hidden type="text" value={code} onChange={handleCode} />
    <button style={{padding:"20px", marginLeft:"5px"}} value="submit">Save</button>
    </form> */}
    </Main>
  </>
  )
}
function Reader(){
  //call file from Storage
  const [file,setFile] = useState({})
  const [bookDisplay, setbookDisplay] = useState("none")
  const pullBook = async(code) => {
    Storage.get(code, { download: true })
    .then((res) =>{ 
      setFile(res.Body)
    }
    ).then(()=>{
      setbookDisplay("flex")

    }).catch((err)=> 
    alert("You enter invalid code, try again"))
  }

  // ----------------------// ----------------------------//
  // transfer to pdf file

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({numPages}){
      setNumPages(numPages)
      setPageNumber(1);
  }

  function changePage(offset) {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
  
    function previousPage() {
      changePage(-1);
    }
  
    function nextPage() {
      changePage(1);
    }

    function onItemClick({ pageNumber: itemPageNumber }) {
      setPageNumber(itemPageNumber);
    }


  return (
    <>
    <div>
      <CodeForm pullBook={pullBook}></CodeForm>
    </div>
    <Main style={{display:bookDisplay, margin: "5%"}}>
      <Zoom>
      <div style={{overflow:"scroll", width: "25vw",height: "100vh"}} >
      <Document style={{fontSize: "1.5rem"}}
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
          >
                <Outline onItemClick={onItemClick} />
        </Document>
        </div>
    </Zoom>
    <Zoom>
    <Document 
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
             
          >
              <Page size="A4" pageNumber={pageNumber} wrap={false}/>
        <div style={{textAlign:"center",margin: "50" }}>
        Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
      </div>
          </Document>
        </Zoom>
      <div style={{ display: "grid", position: "inherit", right: "0%"}}>
        <div style={{position:"fixed"}}>
      <button style={{width: "50%", height: "30%", textAlign:"center"}}
        type="button"
        disabled={pageNumber <= 1}
        onClick={previousPage}
      >
        Prev
      </button>
      <button style={{width: "50%", height: "30%", textAlign:"center",  border: "solid 1px black"}}
        type="button"
        disabled={pageNumber >= numPages}
        onClick={nextPage}
      >
        Next
      </button>
        </div>
      </div>
      </Main>
   </>
  )
}

export default Reader