import React, {useState, useEffect} from 'react'
import { API, graphqlOperation, Storage, Analytics } from 'aws-amplify';
import { deleteBook } from '../../graphql/mutations';
import {Link} from 'react-router-dom';
import { DeleteStyle, Main } from './DeleteBook.styled';
import {AuthorHeader} from '../';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert';

function DeleteBookForm(props){
  //pass title
  const [title, setTitle] = useState( decodeURIComponent(window.location.href.split('/').reverse()[2]))
  //pass id
  const [id, setId] = useState(window.location.href.split('/').reverse()[3])
  const [code, setCode] = useState(window.location.href.split('/').reverse()[1])
  const [version, setVersion] = useState(window.location.href.split('/').reverse()[0])
  const [confirm, setConfirm] = useState('')

  const handleTitle = (e)=> {
    setTitle(e.target.value)
  }

  const handleConfirm = (e)=> {
    setConfirm(e.target.value)
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    if (confirm === 'confirm'){
      props.DelBook(id,version,code);
    }
  }

  return(
    <>
    <Main>
    <AuthorHeader/>
        <DeleteStyle onSubmit={handleSubmit}>
        <h3>
          Delete your book
        </h3>
          <label for="title">Book Title</label>
          <p>
          <input style={{padding:"5px", width:"40vw", marginBottom:"5px",marginTop:"5px"}} type="text" id="title" name="title" value={title}  onChange={handleTitle}/>
          <input style={{padding:"5px", width:"40vw"}} type="text" id="confirm" name="confirm" placeholder="confirm" value={confirm} onChange={handleConfirm}/>
          </p>
          <p>    
          <button variant="danger" style={{padding:"10px",backgroundColor:"#d23a47", color:"white", textAlign:"center"}} value="submit">Delete</button> 
          </p>
        </DeleteStyle>
    </Main>
    </>
  )
}
function DelBook(){
  const DelBook = async(id,version,code) => {
    try {
      await API.graphql(
        graphqlOperation(deleteBook, {input:  
        {
          id: id,
          _version:version
        }
        })
      )
  
      await Storage.remove(code).then (result =>  Swal({title: "Delete successful ", icon: "success"})
      ) 
      .catch(err => console.log(err));
    }
      catch (err){
        console.log(err)
    }
  }

  return (
    <div>
       <DeleteBookForm DelBook={DelBook}> </DeleteBookForm>
    </div>
  )
}

export default DelBook;