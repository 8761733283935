import styled from 'styled-components';
export const Main = styled.div`
    color: black;
    display: block;
    width: 100vw;
    text-align: center;
    a {
        text-decoration: none;
        color: black;
    }
    a:hover, a:active {
            background-color: deepskyblue;
          }
    }
    `;


