import styled from 'styled-components';
export const Main = styled.div`
    color: black;
    display: block;
    width: 100vw
    display: inline-table;
    
    table {
        border-spacing: 0.2rem;
        box-shadow: 5px 10px #888888;
      }
      td {
        padding: 0.2rem;
        text-align: center;
      }
      th {
          background: #CCCC99;
          text-align: center;
      }
      td:nth-child(1) { background: #CCCC99; }
      td:nth-child(2) { background: #CCCC99; }
      td:nth-child(3) { background: white; }
      td:nth-child(4) { background: rgb(210, 58, 71); }
      td:nth-child(5) { background: hsl(190, 90%, 50%); }
      td:nth-child(6) { background: hsl(200, 99%, 50%); }
    `;