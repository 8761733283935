import React, {useState, useEffect} from 'react'
import { API, graphqlOperation, Storage, Analytics } from 'aws-amplify';
import { updateBook } from '../../graphql/mutations';
import {Link} from 'react-router-dom';
import { UpdateStyle, Main } from './UpdateBook.styled';
import {AuthorHeader} from '../';
import { Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert';

function UpdateBookForm(props){
  const [content, setContent] = useState({})
   //pass title
   const [title, setTitle] = useState( decodeURIComponent(window.location.href.split('/').reverse()[1]))
   //pass id
   const [id, setId] = useState(window.location.href.split('/').reverse()[2])
   //pass code book
   const [code, setCode] = useState(window.location.href.split('/').reverse()[0])
  
   const handleTitle = (e)=> {
    setTitle(e.target.value)
  }


  // upload content on S3 as a link to store as html
  const handleContent = (e)=> {
    const file = e.target.files[0]
    setContent({
      fileUrl: URL.createObjectURL(file),
      file,
      filename: file.name
    })

  }

  // when user pull out the code, they can get the link
  const handleSubmit = (e)=>{
    e.preventDefault()
    props.ChangeBook(id,title,content,code)
  }


  return(
    <>
    <Main>
    <AuthorHeader/>
        <UpdateStyle onSubmit={handleSubmit}>
        <h3>
          Update your book
        </h3>
          <label for="title">Book Title</label>
          <input required style={{padding:"5px", width:"40vw"}} type="text" id="title" name="title" placeholder="Book title" value={title} onChange={handleTitle}/>
          <p>
          <label for="title">Upload your book (*.pdf,*.doc, *.docx)</label>
          <input required style={{padding:"5px"}}type="file" id="content" name="content" accept="pdf/*" onChange={handleContent} />
          </p>
          <p>
          <button style={{padding:"10px"}} value="submit">Submit</button>
          </p>
        </UpdateStyle>
    </Main>
    </>
  )
}

function ChangeBook(){
  const ChangeBook = async(id,title,content,code) => {
    try {
      await API.graphql(
        graphqlOperation(updateBook, {input:  
        {
          id: id,
          title:title,
          content:content,
          }
        })
      )
  
      await Storage.put(code,content.file,{
        ContentType:content.file.type,
        body: content
      }).then (result => Swal({title: "Update successful ", icon: "success"})
      ) 
      .catch(err => console.log(err));
    }
      catch (err){
        console.log(err)
    }
  }
  return (
    <div>
       <UpdateBookForm ChangeBook={ChangeBook}></UpdateBookForm>
    </div>
  )
}


export default ChangeBook;

