import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import { AmplifySignOut} from '@aws-amplify/ui-react';

const Menu = ({ open }) => {
  return (
    <StyledMenu open={open}>
    <a  href="/" style={{textAlign:"center"}}>I am Reader </a>
    <a  href="author" style={{textAlign:"center"}}>I am Author </a>
    <a href="logout" ><AmplifySignOut /></a>
    </StyledMenu>
  )
}
Menu.propTypes = {
  open: bool.isRequired,
}
export default Menu;